<template>
  <div class="slider-box">
    <b-button :title="$t('button.close')" variant="link" @click="zoomHandle">
      <i class="demo-icon icon-exit" />
    </b-button>

    <swiper
      v-if="confPhotos && confPhotos.length"
      :options="swiperOption"
      class="gallery-top"
      ref="swiperTop"
      :dir="handleDirection()"
    >
      <swiper-slide v-for="photo in confPhotos" :key="photo.id" class="swiper-slide">
        <div class="slide-show mx-auto p-3">
          <!-- <slot :media="photo"> -->
          <section class="img-wrap ratio-45" v-if="photo.type === 'photo'">
            <div class="img-content">
              <b-img class="slider_image" :src="photo.path" fluid />
            </div>
          </section>

          <iframe
            v-else
            class="work-details__iframe"
            width="100%"
            :src="convertVideo(photo.path)"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <!-- </slot> -->
        </div>
      </swiper-slide>

      <!-- <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>-->
      <div class="swiper-pagination swiper-pagination-white mt-1" slot="pagination"></div>
    </swiper>

    <!-- <swiper
      v-show="showSubSlider"
      v-if="confPhotos"
      :options="swiperOptionThumbs"
      class="gallery-thumbs"
      ref="swiperThumbs"
    >
      <template>
        <swiper-slide
          v-for="photo in confPhotos"
          :key="photo.id"
          class="swiper-slide mb-5"
        >
          <div class="p-3">
            <section class="img-wrap  ratio-25" v-if="photo.type === 'photo'">
              <div class="img-content">
                <b-img :src="photo.path" fluid />
              </div>
            </section>
            <section v-else class="img-wrap  ratio-25">
              <div class="img-content">
                <iframe
                  class="work-details__iframe"
                  width="100%"
                  height="100%"
                  :src="convertVideo(photo.path)"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </section>
          </div>
        </swiper-slide>
      </template>
    </swiper>-->
  </div>
</template>

<script>
// import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import cookies from "js-cookie";
export default {
  props: {
    confPhotos: {
      type: Array,
      default: () => []
    },
    slidesPerViewCount: {
      type: Number,
      default: 4
    },
    showSubSlider: {
      type: Boolean,
      default: true
    }
  },
  components: {
    swiper,
    swiperSlide
  },

  data() {
    return {
      swiperOption: {
        // loop: this.confPhotos.length > 1,
        loop: true,
        loopedSlides: 5,
        slidesPerView: 1,
        spaceBetween: 0,
        margin: 0,
        pagination: {
          el: ".swiper-pagination"
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        },
        autoplay: false,
        navigation: {
          nextEl: ".product-card-slider__next",
          prevEl: ".product-card-slider__previous"
        }
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // }
      },

      swiperOptionThumbs: {
        loop: true,
        spaceBetween: 0,
        slidesPerView:
          this.confPhotos.length < this.slidesPerViewCount
            ? this.confPhotos.length
            : this.slidesPerViewCount,
        touchRatio: 0.2,
        // loop: this.confPhotos.length > 1,
        loopedSlides: 5,
        slideToClickedSlide: true,
        breakpoints: {
          1024: {
            slidesPerView:
              this.confPhotos.length < 4 ? this.confPhotos.length : 4
          },
          769: {
            slidesPerView:
              this.confPhotos.length < 2 ? this.confPhotos.length : 2
          }
        }
      }
    };
  },
  mounted() {
    // if (this.confPhotos) {
    //   this.$nextTick(() => {
    //     const swiperTop = this.$refs.swiperTop.swiper;
    //     const swiperThumbs = this.$refs.swiperThumbs.swiper;
    //     swiperTop.controller.control = swiperThumbs;
    //     swiperThumbs.controller.control = swiperTop;
    //   });
    // }
  },
  methods: {
    convertVideo(video) {
      let url;
      let key;
      var regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
      key = video.match(regex)[1];
      url = video.substring(0, 24) + "embed/" + key;
      return url;
    },
    handleDirection() {
      if (cookies.get("locale") === "ar") {
        return "rtl";
      } else {
        return "ltr";
      }
    },
    zoomHandle() {
      this.$emit("zoomHandle");
    }
  }
};
</script>
